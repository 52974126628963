@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

/* Custom styles */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-gradient-animate {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

/* Board game pattern background */
.bg-board-game-pattern {
  background-color: #f3e8ff;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2V6h4V4H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.5s ease-out;
}

/* Pulsating effect styles */
@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(147, 51, 234, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(147, 51, 234, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(147, 51, 234, 0);
  }
}

.search-pulse {
  animation: pulse-border 3s infinite;
}

.search-pulse:focus {
  animation: none;
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.5);
}

/* Custom font classes */
.font-display {
  font-family: 'Playfair Display', serif;
}

.font-body {
  font-family: 'Roboto', sans-serif;
}

/* Additional custom styles */
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.hover-lift {
  transition: transform 0.3s ease-in-out;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

h1 {
  @apply font-display font-bold text-purple-800 text-shadow;
}

h2 {
  @apply font-display font-semibold text-gray-900;
}

p,
input,
button {
  @apply font-body;
}

.bg-gradient {
  @apply bg-gradient-to-br from-purple-100 to-indigo-100;
}

.search-button {
  @apply bg-purple-600 text-white rounded-full p-4 hover:bg-purple-700 focus:outline-none focus:ring-4 focus:ring-purple-600 focus:ring-opacity-50 transition-all duration-300;
}

.error-message {
  @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg font-body;
}

/* Updated recommendation styles for horizontal layout */
.recommendation-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.recommendation-card {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 16px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.recommendation-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-color: rgba(107, 33, 168, 0.3);
}

.recommendation-thumbnail {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-right: 20px;
}

.recommendation-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recommendation-title {
  font-size: 1.2rem;
  color: #6B21A8;
  margin: 0 0 8px 0;
}

.recommendation-description {
  font-size: 0.9rem;
  color: #4B5563;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .recommendation-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .recommendation-thumbnail {
    width: 100%;
    max-height: 200px;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

/* Skeleton styles with darker background */
.skeleton-card {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  backdrop-filter: blur(5px);
}

.skeleton-thumbnail {
  width: 120px;
  height: 120px;
  background-color: rgba(200, 200, 200, 0.2);
  margin-right: 20px;
}

.skeleton-content {
  flex: 1;
}

.skeleton-line {
  height: 16px;
  background-color: rgba(200, 200, 200, 0.2);
  margin-bottom: 8px;
}

.skeleton-title {
  width: 60%;
}

.skeleton-description {
  width: 80%;
}

/* Dropdown styling */
.dropdown {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdown:focus {
  border-color: #7c3aed;
  box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.3);
  outline: none;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .mt-4.flex.space-x-4 {
    flex-wrap: wrap;
    margin-left: 0; /* Remove any left margin */
    margin-right: 0; /* Remove any right margin */
  }

  .mt-4.flex.space-x-4 > * {
    flex: 1 1 100%;
    margin-bottom: 10px;
    margin-left: 0; /* Remove any left margin */
    margin-right: 0; /* Remove any right margin */
  }

  .dropdown {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .meeple-left-1 {
    left: 10%; /* Adjust this value to position the meeple */
  }
  
  .meeple-left-2 {
    left: 20%; /* Adjust this value to position the meeple */
  }
  
  .meeple-left-3 {
    left: 30%; /* Adjust this value to position the meeple */
  }
}

.dropdown {
  @apply px-4 py-2 rounded-full shadow-md focus:outline-none transition-all duration-300;
  background-color: white;
  border: 2px solid #d1d5db;
  color: #4b5563;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  padding-right: 40px;
}

.dropdown:hover {
  border-color: #9ca3af;
}

.dropdown:focus {
  border-color: #8b5cf6;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.3);
}

/* Dark mode styles */
.dark .dropdown {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23e2e8f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dark .dropdown:hover {
  border-color: #718096;
}

.dark .dropdown:focus {
  border-color: #8b5cf6;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.3);
}

/* Seamless inline autocomplete styles */
.relative {
  position: relative;
}

.autocomplete-suggestion {
  position: absolute;
  top: -0.5px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  padding-left: 1.5rem; /* Match the padding of the input */
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
}

/* Preserve spaces and line breaks */
.suggestion-input,
.suggestion-autocomplete {
  white-space: pre;
  display: inline;
}

.suggestion-input {
  color: transparent;
}

.suggestion-autocomplete {
  color: #a0aec0;
}

/* Ensure the container of the input element has overflow hidden */
.search-container {
  overflow: hidden;
  position: relative;
}

/* Update the input element styles */
input {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
  background: white !important;
  overflow: hidden; /* Hide overflowing text */
  white-space: nowrap; /* Prevent text from wrapping */
  width: 100%; /* Ensure the input takes the full width of the container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

input::placeholder {
  color: #a0aec0;
}

.promoted-content-card {
  /* Style your ad card accordingly */
  background-color: #f9f9f9;
  border: 2px dashed #ccc;
  height: 200px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-details {
  font-size: 0.9rem;
  color: #6B7280;
  margin: 12px 0;
  padding: 8px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.game-details p {
  margin: 0;
}

/* Apply darker background for the dark mode */
.dark .bg-white {
  background-color: #2d3748 !important; /* Darker background for dark mode */
}

.dark .text-gray-900 {
  color: #f7fafc !important; /* Light text color for dark mode */
}

.dark .recommendation-card {
  background-color: #4a5568 !important; /* Darker background for recommendation cards */
}

.dark .promoted-content-card {
  background-color: #000b1f !important; /* Darker background for promoted content */
  border-color: #718096 !important;  /* Darker border in dark mode */
  color: #ccd3da;
}

.dark .skeleton-card {
  background-color: rgba(72, 85, 99, 0.8) !important; /* Darker skeleton card background */
}

.dark .skeleton-thumbnail, .dark .skeleton-line {
  background-color: rgba(72, 85, 99, 0.9) !important; /* Darker skeleton elements */
}

.dark input::placeholder {
  color: #a0aec0 !important; /* Lighter placeholder text color in dark mode */
}

/* Ensure the text in game details is lighter in dark mode */
.dark .game-details {
  color: #ffffff !important;
}

.dark .recommendation-description {
  color: #ccd3da !important;
}

.dark .recommendation-title {
  color: #ccd3da !important;
  font-weight: bold;
}

.youtube-icon, .shopping-cart-icon, .web-icon { /* Add .web-icon */
  position: absolute;
  top: 15px; /* Adjust as needed */
  transition: transform 0.3s ease-in-out;
}

.shopping-cart-icon {
  right: 72px; /* Adjust to position first */
  color: #4A5568; /* Shopping cart color */
}

.dark .shopping-cart-icon {
  right: 72px; /* Adjust to position first */
  color: rgb(196, 196, 196); /* Shopping cart color */
}

.web-icon { /* Add styles for web icon */
  right: 40px; /* Adjust to position next to shopping cart icon */
  color: #357bc2; /* Web icon color */
}

.youtube-icon {
  right: 8px; /* Adjust to position next to web icon */
  color: #ff0505; /* YouTube red color */
}

.youtube-icon:hover, .shopping-cart-icon:hover, .web-icon:hover { /* Add .web-icon */
  transform: scale(1.1);
}

/* Adjust the height of the typing effect container */
.typing-effect-container {
  height: 3rem; /* Reduced from 4rem (h-16) */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure no overflow */
}

.typing-effect-container span {
  display: inline-block;
  vertical-align: middle;
}



.version-switch-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #6B21A8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.version-switch-button:hover {
  background-color: #4A1D96;
}

.dark .version-switch-button {
  background-color: #8B5CF6;
}

.dark .version-switch-button:hover {
  background-color: #7C3AED;
}

.meeple {
  position: absolute;
  top: -22.5px; /* Adjust this value to position the meeple correctly */
  width: 25px; /* Adjust the size as needed */
  height: auto;
  z-index: 1; /* Ensure the meeple is behind the search bar */
}

.meeple-left-1 {
  left: 10%; /* Adjust this value to position the meeple */
}

.meeple-left-2 {
  left: 14%; /* Adjust this value to position the meeple */
}

.meeple-left-3 {
  left: 18%; /* Adjust this value to position the meeple */
}

@media (max-width: 640px) {
  .meeple-left-1 {
    left: 10%; /* Adjust this value to position the meeple */
  }
  
  .meeple-left-2 {
    left: 20%; /* Adjust this value to position the meeple */
  }
  
  .meeple-left-3 {
    left: 30%; /* Adjust this value to position the meeple */
  }
}

@media (max-width: 768px) {
  .typing-effect-container {
    display: none;
  }
  .static-description {
    display: block;
  }
}

@media (min-width: 769px) {
  .static-description {
    display: none;
  }
}

.tab-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  pointer-events: none; /* Ensure it doesn't interfere with input */
}

@media (max-width: 640px) {
  .tab-icon {
    display: none;
  }
}

/* Ensure the autocomplete suggestion is properly contained */
.autocomplete-suggestion {
  position: absolute;
  top: -0.5px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  padding-left: 3rem; /* Match the padding of the input */
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
  overflow: hidden; /* Ensure no overflow */
}

/* Add padding-left to input to accommodate the search icon */
.search-input {
  padding-left: 3rem; /* Adjust as needed */
}

/* Improve dark mode transition */
body, #root, .min-h-screen {
  transition: background-color 0.5s ease, color 0.5s ease;
}

.icon-container {
  display: flex;
  gap: 12px;
  margin-top: 12px;
  justify-content: flex-end;
}

.youtube-icon, .shopping-cart-icon, .web-icon {
  position: static;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
}

.youtube-icon:hover, .shopping-cart-icon:hover, .web-icon:hover {
  transform: scale(1.2);
  opacity: 1;
}

/* Improve dropdown appearance */
.dropdown {
  transition: all 0.3s ease;
  border-width: 1px;
}

.dropdown:hover {
  transform: translateY(-1px);
}

/* Add animation to the search input focus state */
.search-input:focus {
  transform: scale(1.01);
}

.search-form {
  transition: transform 0.3s ease;
}

.search-form:focus-within {
  transform: scale(1.01);
}